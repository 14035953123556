<template>
  <div class="row">
      <div class="row" v-if="form.id">
          <div class="col-sm-6">
              <p class="float-start">
                  <button
                      type="button"
                      class="btn btn-info mr-2"
                      @click="initialPassword()"
                      :disabled="form.password_init_datetime || isDisabledInitPass"
                  >
                      {{ $t('user.init_pass') }}
                  </button>
                  <button
                      type="button"
                      class="btn btn-danger"
                      :disabled="form.login_fail_count < loginFail"
                      @click="userLock()"
                  >{{ $t('user.buton_lock') }}</button>
              </p>
          </div>
          <div class="col-sm-6">
              <p class="float-end">
                  <button type="button" class="btn btn-danger" :disabled="isDisabled" @click="deleteRecord">{{ $t('user.delete') }}</button>
              </p>
          </div>
      </div>
    <div class="col-7">
        <div class="form-group mb-2" v-if="form.login_fail_count > loginFail">
            <span class="color-pink">{{ $t('user.text_lock') }}</span>
        </div>
        <div class="form-group mb-2">
            <div><label for="disable_flg" class="checkbox-forcus"> {{ $t('user.disable_flg') }} </label></div>
            <input type="checkbox" class="form-check-input" id="disable_flg" v-model="formUser.disable_flg" :placeholder="$t('user.disable_flg')" />
        </div>
        <div class="form-group mb-2">
            <div>{{ $t('user.user_mail') }}</div>
            <input type="text" class="form-control" v-model="formUser.user_mail" :placeholder="$t('user.user_mail')" />
            <span v-if="errors.user_mail" class="color-red"> {{ errors.user_mail }} </span>
        </div>
        <div class="form-group mb-2">
            <div>{{ $t('user.user_name') }}</div>
            <input type="text" class="form-control" v-model="formUser.user_name" :placeholder="$t('user.user_name')" />
            <span v-if="errors.user_name" class="color-red"> {{ errors.user_name }} </span>
        </div>
        <div class="form-group mb-2">
            <div>{{ $t('user.user_name_disp') }}</div>
            <input type="text" class="form-control" v-model="formUser.user_name_disp" :placeholder="$t('user.user_name_disp')" />
            <span v-if="errors.user_name_disp" class="color-red"> {{ errors.user_name_disp }} </span>
        </div>
        <div class="form-group mb-2">
            <div>{{ $t('user.user_occupation') }}</div>
            <select class="form-control" v-model="formUser.user_occupation">
                <option v-for="(item, index) in Category" :value="index" :key="index">{{ item }}</option>
            </select>
        </div>
        <div class="form-group mb-2">
            <div> {{ $t('user.user_sys_auth_div') }}</div>
            <div class="form-check form-check-inline" v-for="(item, index) in RoleUser" :key="index">
                <input
                    type="checkbox"
                    :value="index"
                    :key="index"
                    :id="index"
                    @change="datachange"
                    :checked="formUser.user_sys_auth_div.includes(index)">
                <label :for="index" class="checkbox-forcus ml-2">{{  item  }}</label>
            </div>
        </div>
        <div class="form-group mb-2">
            <div>{{ $t('user.disp_seq') }}</div>
            <input type="number" class="form-control" v-model="formUser.disp_seq" />
        </div>
    </div>
    <div class="col-5">
        <div class="row">
            <div class="col-6">
                <div class="form-group mb-2">
                    <div>{{  $t('user.created_by') }}</div>
                    <input type="text" class="form-control" v-model="formUser.created_by" :disabled="true" />
                </div>
            </div>
            <div class="col-6">
                <div class="form-group mb-2">
                    <div>{{ $t('user.updated_by') }}</div>
                    <input type="text" class="form-control" v-model="formUser.updated_by" :disabled="true" />
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-6">
                <div class="form-group mb-2">
                    <div>{{ $t('user.login_last_datetime') }}</div>
                    <input type="text" class="form-control" v-model="formUser.login_last_datetime" :disabled="true" />
                </div>
            </div>
            <div class="col-6">
                <div class="form-group mb-2">
                    <div>{{ $t('user.login_fail_count') }}</div>
                    <input type="text" class="form-control" v-model="formUser.login_fail_count" :disabled="true" />
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-6">
                <div class="form-group mb-2">
                    <div>{{ $t('user.password_init_datetime') }}</div>
                    <input type="text" class="form-control" v-model="formUser.password_init_datetime" :disabled="true" />
                </div>
            </div>
            <div class="col-6">
                <div class="form-group mb-2">
                    <div> {{ $t('user.account_lock_datetime') }} </div>
                    <input type="text" class="form-control" v-model="formUser.account_lock_datetime" :disabled="true" />
                </div>
            </div>
        </div>
    </div>
    <div class="col-xs-12 mt-3">
          <div class="text-end">
              <button type="button" class="btn btn-primary" :disabled="isDisabled" @click="saveForm">{{ $t('user.save') }}</button>
          </div>
      </div>
  </div>
</template>
<script>
import { CATEGORY_CODE_USER, ROLE_CODE_USER, MAX_LOGIN_FAIL } from "@/common/constants";
import { RepositoryFactory } from "@/services/api/RepositoryFactory";
import { CODE_DEFINE_REPOSITORY } from "@/constants";
import { UserFormSchema } from "@/common/schemaValidation";
const CodeRepository = RepositoryFactory.get(CODE_DEFINE_REPOSITORY);

export default {
    props: {
        form: {
            type: Object
        }
    },
    data() {
        return {
            formUser: {
                id: "",
                disable_flg: false,
                user_mail: "",
                user_name: "",
                user_name_disp: "",
                user_occupation: 20,
                user_sys_auth_div: [],
                disp_seq: 0,
                created_by: "",
                updated_by: "",
                login_last_datetime: "",
                login_fail_count: 0,
                password_init_datetime: "",
                account_lock_datetime: ""
            },
            Category: {},
            RoleUser: {},
            isDisabled: false,
            isDisabledLock: false,
            isDisabledInitPass: false,
            errors: {
                user_mail: "",
                user_name: "",
                user_name_disp: ""
            },
            loginFail: MAX_LOGIN_FAIL
        }
    },
    watch: {
        formUser: {
            handler(value){
                this.$emit("changeForm", value)
            },
            deep: true
        }
    },
    async created(){
        const category = await CodeRepository.get(CATEGORY_CODE_USER);
        this.Category = category.data
        const role = await CodeRepository.get(ROLE_CODE_USER);
        this.RoleUser = role.data
        this.formUser = Object.assign({}, this.formUser, this.form)
    },
    methods: {
        saveForm() {
            this.errors = {
                user_mail: "",
                user_name: "",
                user_name_disp: ""
            }
            UserFormSchema
                .validate(this.formUser, { abortEarly: false })
                .then(() => {
                    this.errors = {};
                    this.isDisabled = true
                    this.$emit("saveForm")
                })
                .catch(err => {
                    err.inner.forEach(error => {
                        this.errors[error.path] = error.message;
                    });
                });
        },
        deleteRecord() {
            // eslint-disable-next-line no-alert
            if (confirm("Are you sure that remove")) {
                this.isDisabled = true
                this.$emit("deleteRecord")
            }
        },
        userLock() {
            this.$emit("actionLock")
        },
        initialPassword() {
            this.isDisabledInitPass = true
            this.$emit("initialPassword")
        },
        datachange($e) {
            let checked = $e.target.checked
            let value = $e.target.value
            if (checked) {
                this.formUser.user_sys_auth_div.push(value)
            } else {
                this.formUser.user_sys_auth_div = this.formUser.user_sys_auth_div.filter(item => item !== value)
            }
        }
    }
}
</script>
<style>
.color-pink {
    color: red;
}
.checkbox-forcus {
    cursor: pointer;
}
</style>